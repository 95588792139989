/* You can add global styles to this file, and also import other style files */
.cdk-overlay-container{
  position: absolute;
  z-index: 4 !important;
}
button#print,
button#print * {
  display: block !important;
}

button#download,
button#download * {
  display: block !important;
}

#toolbarViewerMiddle {
  display: none;
}
.cursor-pointer{
  cursor: pointer;
}
